/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

		var $menu = $("#mainmenu").mmenu({
  			"extensions": [
  	            "border-full",
  				"effect-menu-fade",
  				"pagedim-white",
  				"shadow-page",
  				"theme-black",
				"listview-huge"
				// "tileview"
  	        ],
  			"offCanvas": {
  	            "position": "right",
  				"blockUI":true
			},
			"setSelected": {
	            "hover": true,
	            "parent": true
	        },
			"navbar": false
  		},
  		{
  		   // configuration
  		   clone: true,
  		   classNames: {
  	            fixedElements: {
  	               fixed: "fixed"
  	            }
  	        }
  		});

  		var $icon = $("#my-icon");
  		var API = $menu.data( "mmenu" );

  		$icon.on( "click", function() {
  		   API.open();
  		   API.close();
  		});

  		API.bind( "open:start", function( ) {
  	        $icon.addClass( "is-active" );

  	    });
  	    API.bind( "close:start", function( ) {
  	        $icon.removeClass( "is-active" );
  	    });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
	},
	'guide': {
		init: function() {
			// JavaScript to be fired on the guide page



	  	},
	  	finalize: function() {
        	// JavaScript to be fired on the guide, after the init JS
			// .mpmf-type
			// .extroverted-checklist .introverted-checklist
			// .sensing-checklist .intuition-checklist
			// .thinking-checklist .feeling-checklist
			// .judging-checklist .perceiving-checklist

			var chevronOne = "-";
			var chevronTwo = "-";
			var chevronThree = "-";
			var chevronFour = "-" ;

			// var extroverted = "E";
			// var introverted = "I";
			// var sensing = "S";
			// var intuition = "N";
			// var thinking = "T";
			// var feeling = "F";
			// var judging = "J";
			// var perceiving = "P";
			//
			//
			// function theChecker(listDescription) {
			//
			// 	$('.'+listDescription+'-checklist input:checkbox').live('change', function(listDescription){
			// 		var itemChecked = $('.'+listDescription+'-checklist .nf-checked').length;
			// 		// var result = "Number of " + listDescription + " options checked: " + itemChecked;
			// 		return itemChecked;
			// 	});
			//
			// }

			var extrovertedChecked = null;
			var introvertedChecked = null;
			var sensingChecked = null;
			var intuitionChecked = null;
			var thinkingChecked = null;
			var feelingChecked = null;
			var judgingChecked = null;
			var perceivingChecked = null;


			$('.extroverted-checklist input:checkbox').live('change', function(){
				extrovertedChecked = $('.extroverted-checklist .nf-checked').length;
				// console.log("extroverted checked value: " + extrovertedChecked);
			});

			$('.introverted-checklist input:checkbox').live('change', function(){
				introvertedChecked = $('.introverted-checklist .nf-checked').length;
				// console.log("introverted checked value: " + introvertedChecked);
			});

			$('.sensing-checklist input:checkbox').live('change', function(){
				sensingChecked = $('.sensing-checklist .nf-checked').length;
				// console.log("sensing checked value: " + sensingChecked);
			});

			$('.intuition-checklist input:checkbox').live('change', function(){
				intuitionChecked = $('.intuition-checklist .nf-checked').length;
				// console.log("intuition checked value: " + intuitionChecked);
			});

			$('.thinking-checklist input:checkbox').live('change', function(){
				thinkingChecked = $('.thinking-checklist .nf-checked').length;
				// console.log("thinking checked value: " + thinkingChecked);
			});

			$('.feeling-checklist input:checkbox').live('change', function(){
				feelingChecked = $('.feeling-checklist .nf-checked').length;
				// console.log("feeling checked value: " + feelingChecked);
			});

			$('.judging-checklist input:checkbox').live('change', function(){
				judgingChecked = $('.judging-checklist .nf-checked').length;
				// console.log("judging checked value: " + judgingChecked);
			});

			$('.perceiving-checklist input:checkbox').live('change', function(){
				perceivingChecked = $('.perceiving-checklist .nf-checked').length;
				// console.log("perceiving checked value: " + perceivingChecked);
			});



			$('.extroverted-checklist input:checkbox, .introverted-checklist input:checkbox').live('change',function(){
				if (extrovertedChecked > introvertedChecked){
					chevronOne = "E";
				} else if (extrovertedChecked < introvertedChecked) {
					chevronOne = "I";
				} else {
					chevronOne = "-";
				}

				$('.mpmf-type input[type="text"]').val(chevronOne + chevronTwo + chevronThree + chevronFour);
				// console.log(chevronOne + chevronTwo + chevronThree + chevronFour);
			});

			$('.sensing-checklist input:checkbox, .intuition-checklist input:checkbox').live('change',function(){
				if (sensingChecked > intuitionChecked){
					chevronTwo = "S";
				} else if (sensingChecked < intuitionChecked) {
					chevronTwo = "N";
				} else {
					chevronTwo = "-";
				}

				$('.mpmf-type input[type="text"]').val(chevronOne + chevronTwo + chevronThree + chevronFour);
				// console.log(chevronOne + chevronTwo + chevronThree + chevronFour);
			});

			$('.thinking-checklist input:checkbox, .feeling-checklist input:checkbox').live('change',function(){
				if (thinkingChecked > feelingChecked){
					chevronThree = "T";
				} else if (thinkingChecked < feelingChecked) {
					chevronThree = "F";
				} else {
					chevronThree = "-";
				}

				$('.mpmf-type input[type="text"]').val(chevronOne + chevronTwo + chevronThree + chevronFour);
				// console.log(chevronOne + chevronTwo + chevronThree + chevronFour);
			});

			$('.judging-checklist input:checkbox, .perceiving-checklist input:checkbox').live('change',function(){
				if (judgingChecked > perceivingChecked){
					chevronFour = "J";
				} else if (judgingChecked < perceivingChecked) {
					chevronFour = "P";
				} else {
					chevronFour = "-";
				}

				$('.mpmf-type input[type="text"]').val(chevronOne + chevronTwo + chevronThree + chevronFour);
				// console.log(chevronOne + chevronTwo + chevronThree + chevronFour);
			});



			// $('.extroverted-checklist input:checkbox, .introverted-checklist input:checkbox,.sensing-checklist input:checkbox, .intuition-checklist input:checkbox,.thinking-checklist input:checkbox, .feeling-checklist input:checkbox,.judging-checklist input:checkbox, .perceiving-checklist input:checkbox').live('change',function(){
			// 	$('.mpmf-type input[type="text"]').val(chevronOne + chevronTwo + chevronThree + chevronFour);
			// 	console.log(chevronOne + chevronTwo + chevronThree + chevronFour);
			// });


      	}
	}
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
